import { Hero } from "@/components/Hero";
import { useRouter } from "next/router";

export default function (props) {
  const router = useRouter();

  return (
    <Hero
      title="Uh oh! The page you are looking for does not exist"
      description="You may have mistyped the address or the page may have moved."
      buttons={[
        { label: "Return Home", url: "/", variant: "blue" },
        {
          label: "Return Home",
          onclick: (e) => {
            e.preventDefault();
            router.back(-1);
          },
          variant: "light",
        },
      ]}
      {...props}
    />
  );
}
